import React, { useEffect, useRef, useState } from 'react';
import './nav.css';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/Vector.webp';
import { Link as LinkS } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import LangSwitcher from '../LangSwitcher/langSwitcher';
import LangBtn from '../LangSwitcher/LangBtn';

function Nav() {
    const { t } = useTranslation();

    const [navBg, setNavBg] = useState(false);
    const [navMenuActive, setNavMenuActive] = useState(false);
    const navMenuRef = useRef(null);
    const buttonRef = useRef(null);

    const changeBg = () => {
        if (window.scrollY >= 90) {
            setNavBg(true);
        } else {
            setNavBg(false);
        }
    };

    window.addEventListener('scroll', changeBg);

    const [activeNav, setActiveNav] = useState('header');

    useEffect(() => {
        const handleScroll = () => {
            const showcaseOffset = document.getElementById('header').offsetTop;
            const aboutOffset = document.getElementById('about').offsetTop - 200;
            const servOffset = document.getElementById('serv').offsetTop - 200;
            const projectOffset = document.getElementById('project').offsetTop - 200;
            const contactOffset = document.getElementById('contact').offsetTop - 200;
            const currentScrollPos = window.pageYOffset;

            if (currentScrollPos >= showcaseOffset && currentScrollPos < aboutOffset) {
                setActiveNav('#header');
            } else if (currentScrollPos >= aboutOffset && currentScrollPos < servOffset) {
                setActiveNav('#about');
            } else if (currentScrollPos >= servOffset && currentScrollPos < projectOffset) {
                setActiveNav('#serv');
            } else if (currentScrollPos >= projectOffset && currentScrollPos < contactOffset) {
                setActiveNav('#project');
            } else if (currentScrollPos >= contactOffset) {
                setActiveNav('#contact');
            } else {
                setActiveNav('header');
            }
        };

        const handleClick = () => {
            handleScroll();
        };

        const handleSwipe = (e) => {
            const touchEnd = e.changedTouches[0].clientX;
            const touchStart = e.targetTouches[0].clientX;
            const diff = touchEnd - touchStart;
            const threshold = 50;

            if (Math.abs(diff) > threshold) {
                if (diff > 0) {
                    // Swipe right
                } else {
                    // Swipe left
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', handleClick);
        window.addEventListener('touchstart', handleSwipe, false);
        window.addEventListener('touchmove', handleSwipe, false);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', handleClick);
            window.removeEventListener('touchstart', handleSwipe);
            window.removeEventListener('touchmove', handleSwipe);
        };
    }, [activeNav]);

    return (
        <section id='navbar'>
            <nav className={navBg ? 'navbar navbar-bg' : 'navbar'}>
                <div className="nav-container"
                    data-aos="zoom-out"
                    data-aos-easing="ease"
                    data-aos-duration="1500"
                    data-aos-offset="0">
                    <div ref={navMenuRef} className={navMenuActive ? 'topnav show' : 'topnav'} id='nav-container'>
                        <LangBtn />
                        <LinkS to="header" className='Hactive'>{t("Home")}</LinkS>
                        <LinkS to="about" className={activeNav === '#about' ? 'Nactive touch' : 'touch'}>{t("About")}</LinkS>
                        <LinkS to="serv" className={activeNav === '#serv' ? 'Nactive touch' : 'touch'}>{t("Services")}</LinkS>
                        <LinkS to="project" className={activeNav === '#project' ? 'Nactive touch' : 'touch'}>{t("Projects")}</LinkS>
                        <LinkS to="contact" className={activeNav === '#contact' ? 'Nactive touch' : 'touch'}>{t("Contact")}</LinkS>
                    </div>
                    <Navbar.Brand href="/" className='logo'><img src={logo} alt="" /></Navbar.Brand>

                    <LangSwitcher
                        navMenuRef={navMenuRef}
                        navMenuActive={navMenuActive}
                        setNavMenuActive={setNavMenuActive}
                        buttonRef={buttonRef}
                    />
                </div>
            </nav>
        </section>
    )
}

export default Nav;
