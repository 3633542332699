import React from 'react'
import './services.css'
import { useTranslation } from 'react-i18next';

function Services() {

    const { t } = useTranslation();

    return (

        <section id="serv">

            <div className='headline'>
                <h1
                    data-aos="zoom-in"
                    data-aos-offset="100"
                    data-aos-duration="500"><span>{t("servH")}</span></h1>
            </div>
            <div className="serv">
                <div className="container">
                    <div className="parent"
                        data-aos="fade-up"
                        data-aos-offset="-600"
                        data-aos-anchor-placement="bottom-center"
                        data-aos-duration="500"
                    >
                        <div className="card card-1">
                            <div className="content-box">
                                <h1 className="card-title">{t("card1-t")}</h1>
                                <p className="card-content">
                                    {t("card1-p")}
                                </p>
                                {/* <a className="see-more" href="./digital-marketing-page.html"><span>See More</span></a> */}
                            </div>
                        </div>
                    </div>

                    <div className="parent"
                        data-aos="fade-down"
                        data-aos-offset="-700"
                        data-aos-anchor-placement="bottom-center"
                        data-aos-duration="500"
                    >
                        <div className="card card-2">
                            <div className="content-box">
                                <h1 className="card-title">{t("card2-t")}</h1>
                                <p className="card-content">
                                    {t("card2-p")}
                                </p>
                                {/* <a className="see-more" href="./programing page.html"><span>See More</span></a> */}
                            </div>
                        </div>
                    </div>

                    <div className="parent"
                        data-aos="fade-up"
                        data-aos-offset="-600"
                        data-aos-anchor-placement="bottom-center"
                        data-aos-duration="500"
                    >
                        <div className="card card-3">
                            <div className="content-box">
                                <h1 className="card-title">{t("card3-t")}</h1>
                                <p className="card-content">
                                    {t("card3-p")}
                                </p>
                                {/* <a className="see-more" href="./motion graphic.html"><span>See More</span></a> */}
                            </div>

                        </div>
                    </div>

                    <div className="parent"
                        data-aos="fade-down"
                        data-aos-offset="-800"
                        data-aos-anchor-placement="bottom-center"
                        data-aos-duration="500"
                    >
                        <div className="card card-4">
                            <div className="content-box">
                                <h1 className="card-title">{t("card4-t")}</h1>
                                <p className="card-content">
                                    {t("card4-p")}
                                </p>
                                {/* <a className="see-more" href="./video grapher & ads.html"><span>See More</span></a> */}
                            </div>

                        </div>
                    </div>

                    <div className="parent"
                        data-aos="fade-up"
                        data-aos-offset="-800"
                        data-aos-anchor-placement="bottom-center"
                        data-aos-duration="500"
                    >
                        <div className="card card-5">
                            <div className="content-box">
                                <h1 className="card-title">{t("card5-t")}</h1>
                                <p className="card-content">
                                    {t("card5-p")}
                                </p>
                                {/* <a className="see-more" href="./social media managment.html"><span>See More</span></a> */}
                            </div>

                        </div>
                    </div>

                    <div className="parent"
                        data-aos="fade-down"
                        data-aos-offset="-800"
                        data-aos-anchor-placement="bottom-center"
                        data-aos-duration="500"
                    >
                        <div className="card card-6">
                            <div className="content-box">
                                <h1 className="card-title">{t("card6-t")}</h1>
                                <p className="card-content">
                                    {t("card6-p")}
                                </p>
                                {/* <a className="see-more" href="./outdoor advertising.html"><span>See More</span></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services