import React from 'react';
import projt1 from '../../assets/project1.webp';
import projt2 from '../../assets/project2.webp';
import projt3 from '../../assets/project3.webp';
import './Project.css';
import { useTranslation } from 'react-i18next';



const Projects = () => {
    const { t } = useTranslation();




    return (
        <section className="projects-wrapper" id='project'>
            <div className='project'>
                <div className='headline'>
                    <h1 data-aos='zoom-in' data-aos-offset='100' data-aos-duration='500'>
                        <span>{t('Projects')}</span>
                    </h1>
                </div>
                <div className="projects-container"
                    data-aos="fade-left"
                    data-aos-offset="200"
                    data-aos-duration="800">
                    <section className="project-section">
                        <div className="about-top">
                            <div className="about-img" >
                                <img src={projt1} width={250} alt="" />
                            </div>
                            <div className='about-content-bg'
                            >
                                <div className='about-content'>
                                    <div className='aboutH'>
                                        <h2 >{t('ProjectH1')}</h2>
                                    </div>
                                    <div className='aboutP'>
                                        <p className='scroll-barr'>{t('ProjectP1')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="project-section"
                        data-aos="fade-right"
                        data-aos-offset="200"
                        data-aos-duration="800" >
                        <div className="about-top">
                            <div className="about-img" >
                                <img src={projt2} width={250} alt="" />
                            </div>
                            <div className='about-content-bg'>
                                <div className='about-content'>
                                    <div className='aboutH'>
                                        <h2 >{t('ProjectH2')}</h2>
                                    </div>
                                    <div className='aboutP'>
                                        <p className='scroll-barr'>{t('ProjectP2')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="project-section"
                        data-aos="fade-left"
                        data-aos-offset="150"
                        data-aos-duration="800" >
                        <div className="about-top">
                            <div className="about-img" >
                                <img src={projt3} width={250} alt="" />
                            </div>
                            <div className='about-content-bg'>
                                <div className='about-content'>
                                    <div className='aboutH'>
                                        <h2 >{t('ProjectH3')}</h2>
                                    </div>
                                    <div className='aboutP'>
                                        <p className='scroll-barr'>{t('ProjectP3')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    );
};

export default Projects;
