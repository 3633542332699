/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { HiBars3BottomLeft } from "react-icons/hi2";



function LangSwitcher({ navMenuRef, navMenuActive, setNavMenuActive, buttonRef }) {

    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('lng', lng);
        window.history.replaceState(null, null, `?${urlParams.toString()}`);
        window.location.reload(); // Reload the window after changing language
    };

    const languages = [
        { code: "en", lang: "EN" },
        { code: "ar", lang: "AR" },
    ];



    const toggleNavMenuClass = () => {
        setNavMenuActive(prevState => !prevState);
    };

    const handleClickOutside = (event) => {
        if (
            navMenuRef.current &&
            !navMenuRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target)
        ) {
            setNavMenuActive(false);
        }
    };

    useEffect(() => {
        if (navMenuActive) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navMenuActive]);



    return (
        <div className='lng-btn'>
            <div className='menu-button'>
                <button ref={buttonRef} className='menu-btn' onClick={toggleNavMenuClass}><i><HiBars3BottomLeft /></i></button>
            </div>
            <div className='lang-buttons'>
                {languages.map((lng) => {
                    return (
                        <button
                            className={lng.code === i18n.language ? "selected" : ""}
                            key={lng.code}
                            onClick={() => changeLanguage(lng.code)}
                        >
                            {lng.lang}
                        </button>
                    );
                })}
            </div>
        </div>
    )
}

export default LangSwitcher