import './contact.css'
import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";
import { BsFillPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { TbClockHour4Filled } from "react-icons/tb";
import { PiSnapchatLogo } from "react-icons/pi";
import { SlSocialLinkedin } from "react-icons/sl";
import { PiTiktokLogo } from "react-icons/pi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import i18n from 'i18next';

function Contact() {

    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
        const servicesId = "service_bcpytho";
        const templateId = "template_ppfinfh";
        const publikKey = "y01dtFdHmKYNdH0Rx";

        const templateParams = {
            from_name: name,
            from_email: email,
            phone_num: phone,
            message: message
        };
        emailjs.send(servicesId, templateId, templateParams, publikKey)
            .then((response) => {
                alert("Email sent successfully \n تم الإرسال بنجاح", response);
                setName('');
                setEmail('');
                setPhone('');
                setMessage('');
            })
            .catch((error) => {
                alert("Failed to send message \n تعذر إرسال الرسالة", error);
            });
    };

    const textStyle = {
        letterSpacing: i18n.language === 'en' ? '5px' : '1px',
    };

    return (
        <section id='contact'>
            <div className='headline'>
                <h1 data-aos="zoom-in"
                    data-aos-offset="100"
                    data-aos-duration="500"><span>{t("contactH")}</span></h1>
            </div>
            <div className="contact-container">
                <div className="conatct-top">
                    <div className="cont-left">
                        <div>
                            <div className="bord phone"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="300">
                                <i className='phone'><BsFillPhoneFill /></i>
                                <div className='bord-cont'>
                                    <p className='H'>{t("phoneH")}</p>
                                    <p>966570398575+ - 966563066851+</p>
                                </div>

                            </div>
                            <div className="bord mail"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="500">
                                <i className='mail'><MdEmail /></i>
                                <div className='bord-cont'>
                                    <p>{t("mailH")}</p>
                                    <p>missandev@gmail.com</p>
                                </div>
                            </div>
                            <div className="bord address"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="700">
                                <i className='address'><FaMapMarkerAlt /></i>
                                <div className='bord-cont'>
                                    <p>{t("addressH")}</p>
                                    <p>{t("addressP")}</p>
                                </div>

                            </div>
                            <div className="bord time"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="900">
                                <i className='time'><TbClockHour4Filled /></i>
                                <div className='bord-cont'>
                                    <p>{t("timeH")}</p>
                                    <p>{t("timeP")}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='Sicon-container'>
                            <div className=" Sicon"
                                data-aos="fade-down-left"
                                data-aos-offset="80"
                                data-aos-duration="500"
                            >
                                <a href="https://www.snapchat.com/add/missandev?share_id=7OAjh0EcN-k&locale=en-US"
                                    target="_blank"
                                    rel="noreferrer"><i className='time'><PiSnapchatLogo /></i></a>
                            </div>
                            <div className=" Sicon"
                                data-aos="fade-down-left"
                                data-aos-offset="80"
                                data-aos-duration="800"
                            >
                                <a href="https://www.linkedin.com/in/missan-elmaharya-6a5033309/"
                                    target="_blank"
                                    rel="noreferrer"><i className='time'><SlSocialLinkedin /></i></a>
                            </div>
                            <div className=" Sicon"
                                data-aos="fade-down-left"
                                data-aos-offset="80"
                                data-aos-duration="1100"
                            >
                                <a href="https://www.tiktok.com/@missandev"
                                    target="_blank"
                                    rel="noreferrer"><i className='time'><PiTiktokLogo /></i></a>
                            </div>
                            <div className=" Sicon"
                                data-aos="fade-down-left"
                                data-aos-offset="80"
                                data-aos-duration="1400"
                            >
                                <a href="https://x.com/missandev"
                                    target="_blank"
                                    rel="noreferrer"><i className='time'><FaXTwitter /></i></a>
                            </div>
                            <div className=" Sicon"
                                data-aos="fade-down-left"
                                data-aos-offset="80"
                                data-aos-duration="1700"
                            >
                                <a href="/"
                                    target="_blank"
                                    rel="noreferrer"><i className='time'><FaInstagram /></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bot-container">
                    <div className='bot-container-bg'
                        data-aos="zoom-in-up"
                        data-aos-offset="200"
                        data-aos-duration="1000"
                    >
                        <div className="cont-input">
                            <div>
                                <h2 data-aos="zoom-in-up"
                                    data-aos-offset="200"
                                    data-aos-duration="800" style={textStyle} className='msgH'>{t("msgH")}</h2>
                            </div>

                            <div className="input">
                                <form onSubmit={sendEmail} className='emailForm'>
                                    <div className='top-col'>
                                        <input
                                            data-aos="fade-down-left"
                                            data-aos-offset="200"
                                            data-aos-duration="1000"
                                            name='name'
                                            placeholder={t("ename")}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                        <input
                                            data-aos="fade-down-left"
                                            data-aos-offset="200"
                                            data-aos-duration="1000"
                                            name='email'
                                            placeholder={t("email")}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        <input
                                            data-aos="fade-down-left"
                                            data-aos-offset="200"
                                            data-aos-duration="1000"
                                            name='Phone'
                                            placeholder={t("phone")}
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            required
                                        />
                                        <textarea
                                            data-aos="fade-down-left"
                                            data-aos-offset="180"
                                            data-aos-duration="1000"
                                            placeholder={t("mesg")}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)} required />
                                    </div>
                                    <div data-aos="fade-down-left"
                                        data-aos-offset="40"
                                        data-aos-duration="1000">
                                        <div className='send-btn'><button type='submit' className='sub-btn'> {t("send")}</button></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact