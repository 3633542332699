import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import HomePage from './Pages/HomePage.jsx'
import i18n from './i18n.js'
import AboutPage from './Pages/AboutPage/AboutPage.jsx'
import Icon from './components/header/Icon.jsx'
import './i18n.js'


function App() {

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: 'ease',
      once: false
    });

    const handleResize = () => {
      AOS.refresh();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const LanguageHandler = () => {
    const query = useQuery();
    const lng = query.get('lng');

    useEffect(() => {
      if (lng) {
        i18n.changeLanguage(lng);
      }
    }, [lng]);

    return null;
  };


  return (
    <Router>
      <LanguageHandler />
      <div id='main'>
        <Icon />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/aboutus/' element={<AboutPage />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App