import React, { useState, useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";

function Icon() {
    const [iShow, setiShow] = useState(false);

    const ShowIcon = () => {
        if (window.scrollY >= 400) {
            setiShow(true);
        } else {
            setiShow(false);
        }
    };

    const scrollToTop = (event) => {
        event.preventDefault();  // Prevent the default anchor behavior
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', ShowIcon);
        return () => {
            window.removeEventListener('scroll', ShowIcon);
        };
    }, []);

    return (
        <div className="sticky-i">
            <div
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="600"
                data-aos-offset="0"
            >
                <a
                    href="#top"
                    onClick={scrollToTop}
                    className={iShow ? 'S-btn' : 'S-btn hidden'}
                >
                    <IoIosArrowUp />
                </a>
            </div>
            <div
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="600"
            >
                <a
                    className="S-btn"
                    href="https://api.whatsapp.com/send?phone=966570398575"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FaWhatsapp />
                </a>
            </div>
        </div>
    );
}

export default Icon;
