/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import './about.css'
import aboutimg from '../../assets/about-img.webp'
import aboutIcon1 from "../../assets/fast.webp";
import aboutIcon2 from "../../assets/target.webp";
import aboutIcon3 from "../../assets/creative.webp";
import aboutIcon4 from "../../assets/security.webp";
import aboutIcon5 from "../../assets/quality.webp";
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';



function About() {
    const { t } = useTranslation();


    const textStyle = {
        letterSpacing: i18n.language === 'en' ? '2px' : '0px',
    };


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const lng = urlParams.get('lng');
        if (lng) {
            i18n.changeLanguage(lng);
        }
    }, []);

    return (
        <div>
            <div id="about" className="about">
                <div className="about-container">
                    <div className="about-top">
                        <div className="about-img"
                            data-aos="fade-down"
                            data-aos-offset="200"
                            data-aos-duration="800"
                        >
                            <img src={aboutimg} width={250} alt="" />
                        </div>
                        <div className='about-content-bg'
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="800">
                            <div className='about-content'>
                                <div className='aboutH'>
                                    <h2 style={textStyle}>{t('aboutH')}</h2>
                                </div>
                                <div className='aboutP'>
                                    <p className='scroll-bar' style={textStyle}>{t('aboutP')}</p>
                                </div>
                                <div className='see-more'> <Link to='/aboutus/'> {t('SeeMore')}</Link> <span> &gt;</span> </div>
                            </div>
                        </div>
                    </div>

                    <div className="about-bot">
                        <ul className='about-list'>
                            <div >
                                <li className='about-icon'
                                    data-aos="fade-up"
                                    data-aos-offset="100"
                                    data-aos-duration="500"
                                >
                                    <i><img src={aboutIcon1} alt="" /></i>
                                    <h4 style={textStyle}>{t('icon1')}</h4>
                                </li>
                            </div>
                            <div>
                                <li className='about-icon'
                                    data-aos="fade-down"
                                    data-aos-offset="100"
                                    data-aos-duration="500"
                                >
                                    <i><img src={aboutIcon2} alt="" /></i>
                                    <h4 style={textStyle}>{t('icon2')}</h4>
                                </li>
                            </div>
                            <div>
                                <li className='about-icon'
                                    data-aos="fade-up"
                                    data-aos-offset="100"
                                    data-aos-duration="500"
                                >
                                    <i><img src={aboutIcon3} alt="" /></i>
                                    <h4 style={textStyle}>{t('icon3')}</h4>
                                </li>
                            </div>
                            <div>
                                <li className='about-icon'
                                    data-aos="fade-down"
                                    data-aos-offset="100"
                                    data-aos-duration="500"
                                >
                                    <i><img src={aboutIcon4} alt="" /></i>
                                    <h4 style={textStyle}>{t('icon4')}</h4>
                                </li>
                            </div>
                            <div>
                                <li className='about-icon'
                                    data-aos="fade-up"
                                    data-aos-offset="100"
                                    data-aos-duration="500"
                                >
                                    <i><img src={aboutIcon5} alt="" /></i>
                                    <h4 style={textStyle}>{t('icon5')}</h4>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About