import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import dataAR from './Locale/ar/translation.json';
import dataEN from './Locale/en/translation.json';

const resources = {
    en: {
        translation: dataEN
    },
    ar: {
        translation: dataAR
    },
};


const customLanguageDetector = {
    type: 'languageDetector',
    async: false,
    detect: () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('lng') || 'ar';
    },
    init: () => { },
    cacheUserLanguage: (lng) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('lng', lng);
        window.history.replaceState(null, null, `/?${urlParams.toString()}`);
    }
};

i18n
    .use(customLanguageDetector)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        resources,
        fallbackLng: "ar",
        detection: {
            order: [
                'customDetector',
                'cookie',
                'htmlTag',
                'localStorage',
                'sessionStorage',
                'navigator',
                'htmlTag',
                'path',
                'subdomain'
            ],
            caches: ["cookie"],
        },
        backend: {
            loadPath: './Locale/{{lng}}/translation.json',
        },
    });

export default i18n;
