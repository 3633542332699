import React, { useRef, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/Vector.webp';
import LangBtn from '../../components/LangSwitcher/LangBtn';
import { useTranslation } from 'react-i18next';
import LangSwitcher from '../../components/LangSwitcher/langSwitcher';

function NavPages() {
    const { t } = useTranslation();

    const [navBg, setNavBg] = useState(false);
    const [navMenuActive, setNavMenuActive] = useState(false);
    const navMenuRef = useRef(null);
    const buttonRef = useRef(null);

    const changeBg = () => {
        if (window.scrollY >= 90) {
            setNavBg(true);
        } else {
            setNavBg(false);
        }
    };

    window.addEventListener('scroll', changeBg);

    const scrollToDown = (event) => {
        event.preventDefault();  // Prevent the default anchor behavior
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    };

    const scrollToTop = (event) => {
        event.preventDefault();  // Prevent the default anchor behavior
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <section id='navbar'>
            <nav className={navBg ? 'navbar navbar-bg' : 'navbar'}>
                <div className="nav-container"
                    data-aos="zoom-out"
                    data-aos-easing="ease"
                    data-aos-duration="1500"
                    data-aos-offset="0">
                    <div ref={navMenuRef} className={navMenuActive ? 'topnav show' : 'topnav'} id='nav-container'>
                        <LangBtn />
                        <a href="#top" className='Hactive' onClick={scrollToTop}>{t("Home")}</a>
                        <a onClick={scrollToDown} href="#down" className='touch'>{t("Contact")}</a>
                    </div>
                    <Navbar.Brand href="/" className='logo'><img src={logo} alt="" /></Navbar.Brand>

                    <LangSwitcher
                        navMenuRef={navMenuRef}
                        navMenuActive={navMenuActive}
                        setNavMenuActive={setNavMenuActive}
                        buttonRef={buttonRef}
                    />
                </div>
            </nav>
        </section>
    )
}

export default NavPages;
