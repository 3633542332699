import React from 'react'
import { useTranslation } from 'react-i18next';






function LangBtn() {

    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('lng', lng);
        window.history.replaceState(null, null, `?${urlParams.toString()}`);
        window.location.reload(); // Reload the window after changing language
    };

    const languages = [
        { code: "en", lang: "EN" },
        { code: "ar", lang: "AR" },
    ];


    return (
        <div className='lng-btn menu-btn'>
            {languages.map((lng) => {
                return (
                    <button
                        className={lng.code === i18n.language ? "selected" : ""}
                        key={lng.code}
                        onClick={() => changeLanguage(lng.code)}
                    >
                        {lng.lang}
                    </button>
                );
            })}
        </div>
    )
}




export default LangBtn