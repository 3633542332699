/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import './Footer.css';
import Flogo from "../../assets/Vector.webp"
import { Link as LinkS } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { BsFillPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { TbClockHour4Filled } from "react-icons/tb";

function Footer() {
    const { t } = useTranslation();

    return (

        <footer id='footer'>
            <div className="footer">
                <div className="Fmap column"
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-duration="1500">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d475246.46967585746!2d38.7342461427788!3d21.
                        4738897814569!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13
                        .1!3m3!1m2!1s0x15c3d01fb1137e59%3A0xe059579737b118db!2sJeddah%20Saudi
                        %20Arabia!5e0!3m2!1sen!2seg!4v1715446576839!5m2!1sen!2seg"
                        allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className='mobile'>
                    <div style={{ width: '50%' }} id='footerone' className="column"
                        data-aos="fade-up"
                        data-aos-offset="100"
                        data-aos-duration="1500">
                        <h3>{t("Fcol2")}</h3>
                        <div className="bord phone">
                            <i className='phone'><BsFillPhoneFill /></i>
                            <div className='bord-cont'>
                                <p className='H'>{t("phoneH")}</p>
                                <p>966570398575+ - 966563066851+</p>
                            </div>

                        </div>
                        <div className="bord mail">
                            <i className='mail'><MdEmail /></i>
                            <div className='bord-cont'>
                                <p>{t("mailH")}</p>
                                <p>missandev@gmail.com</p>
                            </div>

                        </div>
                        <div className="bord address">
                            <i className='address'><FaMapMarkerAlt /></i>
                            <div className='bord-cont'>
                                <p>{t("addressH")}</p>
                                <p>{t("addressP")}</p>
                            </div>

                        </div>
                        <div className="bord time">
                            <i className='time'><TbClockHour4Filled /></i>
                            <div className='bord-cont'>
                                <p>{t("timeH")}</p>
                                <p>{t("timeP")}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '50%' }} id='footertow' className="column"
                        data-aos="fade-up"
                        data-aos-offset="100"
                        data-aos-duration="1500">
                        <h3>{t("Fcol3")}</h3>
                        <ul>
                            <li><LinkS to="header">{t("Home")} </LinkS></li>
                            <div className='under-border'></div>
                            <li><LinkS to="about">{t("About")}</LinkS></li>
                            <div className='under-border'></div>
                            <li><LinkS to="serv">{t("Services")}</LinkS></li>
                            <div className='under-border'></div>
                            <li><LinkS to="project">{t("Projects")}</LinkS></li>
                            <div className='under-border'></div>
                            <li> <LinkS to="contact">{t("Contact")}</LinkS></li>
                            <div className='under-border'></div>
                        </ul>
                    </div>
                </div>
                <div className="column C" data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-duration="1500">
                    <div className='Fbord'>
                        <img src={Flogo} width={140} alt="Image" />
                    </div>
                </div>
            </div>
            <div className='footer-end'>
                <h3> {t("core")} <span> {t("missan")} </span></h3>
            </div>
        </footer >
    )
}

export default Footer