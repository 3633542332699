/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import aboutimg from '../../assets/about-img.webp'
import aboutimg2 from '../../assets/about-img2.webp'
import aboutimg3 from '../../assets/about-img3.webp'
import aboutIcon1 from "../../assets/fast.webp";
import aboutIcon2 from "../../assets/target.webp";
import aboutIcon3 from "../../assets/creative.webp";
import aboutIcon4 from "../../assets/security.webp";
import aboutIcon5 from "../../assets/quality.webp";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import NavPages from '../navPages/NavPages';
import cookies from 'js-cookie';
import FooterPages from '../FooterPages/FooterPages';
import { Helmet } from 'react-helmet';



function AboutPage() {
    const { t } = useTranslation();



    const lng = cookies.get("i18next") || "";
    useEffect(() => {

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);

        const contactElement2 = document.querySelector('#footer');
        const contactElement5 = document.querySelector('#about');

        if (contactElement2 && contactElement5) {
            contactElement2.dir = i18n.dir();
            contactElement5.dir = i18n.dir();

        }
    }, [i18n, lng]);

    const textStyle = {
        letterSpacing: i18n.language === 'en' ? '2px' : '0px',
    };

    return (

        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>من نحن - About Us</title>
                <link rel="canonical" href="https://missanvip.com/" />
                <meta name="description" content="نحن ميسان المهارية ، شركة برمجة سعودية ذات مسؤولية محدودة تتميز بتقديم خدمات تقنية إبداعية ومتنوعة" />
            </Helmet>
            <NavPages />

            <section id="about" className="about">
                <br />
                <br />
                <br />
                <br />
                <div className='headline'>
                    <h1
                        data-aos="zoom-in"
                        data-aos-offset="100"
                        data-aos-duration="500"><span>{t("AboutH")}</span></h1>
                </div>
                <div className="about-container">
                    <div className="about-top">
                        <div className="about-img"
                            data-aos="fade-down"
                            data-aos-offset="200"
                            data-aos-duration="800"
                        >
                            <img src={aboutimg} width={250} alt="" />
                        </div>
                        <div className='about-content-bg'
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="800">
                            <div className='about-content'>
                                <div className='aboutH'>
                                    <h2 style={textStyle} >{t('aboutH')}</h2>
                                </div>
                                <div className='aboutP'>
                                    <p className='scroll-bar' style={textStyle}>{t('aboutP')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="about-top">
                        <div className="about-img"
                            data-aos="fade-down"
                            data-aos-offset="200"
                            data-aos-duration="800"
                        >
                            <img src={aboutimg2} width={250} alt="" />
                        </div>
                        <div className='about-content-bg'
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="800">
                            <div className='about-content'>
                                <div className='aboutH'>
                                    <h2 style={textStyle}>{t('visionH')}</h2>
                                </div>
                                <div className='aboutP'>
                                    <p style={textStyle}>{t('visionP')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="about-top">
                        <div className="about-img"
                            data-aos="fade-down"
                            data-aos-offset="200"
                            data-aos-duration="800"
                        >
                            <img src={aboutimg3} width={250} alt="" />
                        </div>
                        <div className='about-content-bg'
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="800">
                            <div className='about-content'>
                                <div className='aboutH'>
                                    <h2 style={textStyle}>{t('missionH')}</h2>
                                </div>
                                <div className='aboutP'>
                                    <p className='scroll-barr' style={textStyle}>{t('missionP')}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='coreV' style={{ width: '100%' }}>
                        <div className='headline'>
                            <h1
                                data-aos="zoom-in"
                                data-aos-offset="100"
                                data-aos-duration="500"><span>{t("values")}</span></h1>
                        </div>
                    </div>
                    <div className="about-bot">
                        <ul className='about-list'>
                            <div >
                                <li className='about-icon'
                                    data-aos="fade-up"
                                    data-aos-offset="100"
                                    data-aos-duration="500"
                                >
                                    <i><img src={aboutIcon1} alt="" /></i>
                                    <h4 style={textStyle}>{t('icon1')}</h4>
                                </li>
                            </div>
                            <div>
                                <li className='about-icon'
                                    data-aos="fade-down"
                                    data-aos-offset="100"
                                    data-aos-duration="500"
                                >
                                    <i><img src={aboutIcon2} alt="" /></i>
                                    <h4 style={textStyle}>{t('icon2')}</h4>
                                </li>
                            </div>
                            <div>
                                <li className='about-icon'
                                    data-aos="fade-up"
                                    data-aos-offset="100"
                                    data-aos-duration="500"
                                >
                                    <i><img src={aboutIcon3} alt="" /></i>
                                    <h4 style={textStyle}>{t('icon3')}</h4>
                                </li>
                            </div>
                            <div>
                                <li className='about-icon'
                                    data-aos="fade-down"
                                    data-aos-offset="100"
                                    data-aos-duration="500"
                                >
                                    <i><img src={aboutIcon4} alt="" /></i>
                                    <h4 style={textStyle}>{t('icon4')}</h4>
                                </li>
                            </div>
                            <div>
                                <li className='about-icon'
                                    data-aos="fade-up"
                                    data-aos-offset="100"
                                    data-aos-duration="500"
                                >
                                    <i><img src={aboutIcon5} alt="" /></i>
                                    <h4 style={textStyle}>{t('icon5')}</h4>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </section>
            <FooterPages />
        </div>
    )
}

export default AboutPage