import React from 'react'
import './header.css'
import { TypeAnimation } from 'react-type-animation';
import Sicon from '../header/Icon'
import logo from '../../assets/Vector.webp'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function Header() {
    const { t } = useTranslation();

    const textStyle = {
        letterSpacing: i18n.language === 'en' ? '3.3px' : '0px',
    };

    // const textClass = i18n.language === 'en' ? 'english-text' : 'arabic-text';
    return (
        <div>
            <section id='header' className='header'
                data-aos="zoom-out"
                data-aos-easing="linear"
                data-aos-duration="600"
                data-aos-offset="0"
            >
                <div className='home'>
                    <div className='homeContent'>
                        <img src={logo} alt="" />

                        <TypeAnimation
                            className='typing-text'
                            sequence={[
                                t('missanName'),
                                1200,

                                t('slideoneB'),
                                5000,

                            ]}
                            wrapper="span"
                            speed={200}
                            style={textStyle}
                            repeat={Infinity}
                        />

                        <h4 style={textStyle}> <span>{t('mark1')}</span> {t('slideone')} <span>{t('mark2')}</span> </h4>
                    </div>
                </div>
            </section >
            <Sicon />
        </div>
    )
}

export default Header